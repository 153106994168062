<template>
    <div class="app-container">
        <div class="filter-container">

            <el-input
                :placeholder="$t('streamManager.serverIp')"
                maxlength="20"
                v-model="listQuery.serverIp"
                clearable
                style="width: 200px"
                class="filter-item"
                @keyup.enter.native="handleFilter"
            />
            <el-input
                :placeholder="$t('streamManager.streamId')"
                maxlength="50"
                v-model="listQuery.streamId"
                clearable
                style="width: 200px"
                class="filter-item"
                @keyup.enter.native="handleFilter"
            />
            <el-button
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                :loading="listLoading"
                @click="handleFilter"
            >{{ $t("commons.search") }}
            </el-button>
        </div>
        <el-table
            v-loading="listLoading"
            :data="tableListData"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row
            stripe
        >
            <el-table-column
                type="index"
                align="center"
                :resizable="false"
                :label="$t('commons.index')"
                width="80"
            >
            </el-table-column>
            <el-table-column
                prop="serverIp"
                :label="$t('streamManager.streamServerIp')"
                width="300"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="streamId"
                :label="$t('streamManager.streamId')"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="clientNumber"
                :label="$t('streamManager.onlineNumber')"
                align="center"
            >
            </el-table-column>
            <el-table-column
                :label="$t('streamManager.play')"
                align="center"
                width="100"
            >
                <template v-slot="scope">
                    <el-button @click="onClickToPlay(scope.row)" type="text" size="medium">▶</el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="listQuery.current"
            :limit.sync="listQuery.rowCount"
            @pagination="getAllStream"
        />

        <template>
            <el-dialog :visible.sync="Visiable" @close = "onVideoClose()">
                <div class="video-component">
                    <Player ref = "video"
                            :video-url="videoUrl"
                            height="480px"
                            :autoplay="true"
                            :has-audio="false"
                            :isFullResize="false"
                    />
                </div>
            </el-dialog>
        </template>
    </div>
</template>

<script>
import { getAll, getAllServers, getClients, getStreams } from "@/api/ruge/stream/streamManager";
import Pagination from "@/components/Pagination";
import Player from "@/components/jessibuca/index.vue";
export default {
    name: "StreamManager",
    components: {Player, Pagination},
    data() {
        let opt = {
            appList: [],
            list: null,
            streamList: [],
            tableListData:[],
            Visiable : false,
            videoUrl : '',
            player: '⏯',
            total: 0,
            listLoading: true,
            listQuery: {
                current: 1,
                rowCount: 10,
                serverIp: "",
                streamId: "",
            },
        };
        return opt;
    },
    created() {
        this.getAllStream();
    },
    watch: {
        // 切换语言重新设置快捷选项
        language: function () {
            this.setPickerShortcuts();
        },
        dataList() {
            this.tableListData = this.streamList.slice(
                (this.currentPage - 1) * this.limit,
                this.currentPage * this.limit
            );
        }
    },
    computed: {
        language() {
            return this.$store.getters.language;
        },
    },
    methods: {
        onVideoClose(){
            this.$refs.video.destroy();
        },
        onClickToPlay(row, event, column){
            this.Visiable=true;
            this.videoUrl = row.domain+row.streamId+'.flv';
            this.$refs.video.videoUrl = this.videoUrl;
            this.$refs.video.play(this.videoUrl);
        },

        handleFilter() {
            this.listQuery.current = 1;
            this.getAllStream();
        },
        getAllStream(){
            this.listLoading = true;
            getAll(this.listQuery).then((res)=>{
                this.streamList = res;
                this.listLoading = false;
                this.total = this.streamList.length;
                this.tableListData = this.streamList.slice(
                    (this.listQuery.current - 1) * this.listQuery.rowCount,
                    this.listQuery.current * this.listQuery.rowCount
                );
            })
        }
    },
};
</script>
<style lang="less" scoped>
.app-container{

}
.app-container /deep/ .el-dialog__header{
    padding: 10px !important;
    height: 0;
    .el-dialog__headerbtn{
        top: 12px !important;
        right: 12px;
    }
}

.view-span {
    cursor: pointer;
    color: #1a4cec;
}
</style>