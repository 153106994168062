import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 获取所有服务器列表（包含字符子服务器）
 * @param params
 * @returns {*}
 */
export function getAll(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/mediaStream/getAll',
    method: 'get',
    params,
  });
}

/**
 * 获取所有服务器列表（不包含字符子服务器）
 * @param params
 * @returns {*}
 */
export function getAllServers(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/mediaStream/getAllServers',
    method: 'get',
    params,
  });
}

/**
 * 获取所有客户端列表
 * @param params
 * @returns {*}
 */
export function getClients(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/mediaStream/getClients',
    method: 'get',
    params,
  });
}

/**
 * 获取所有流列表
 * @param params
 * @returns {*}
 */
export function getStreams(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/mediaStream/getStreams',
    method: 'get',
    params,
  });
}

/**
 * 查询应用列表
 * @param params
 */
export function findAppList(params) {
  return request({
    url:
        envInfo.bgApp.lookupPath +
        `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
    method: 'get',
  });
}
